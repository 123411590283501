<template>
    <div>
        <div class="card">
            <TransitionGroup name="fade" tag="div" class="card-body" mode="out-in">
                <div class="d-flex flex-row my-2" v-for="(ders,index) in paket.lessons" :class="{ 'active-ders': ders.active }" :key="index" >
                    <div class="col flex-grow-1">
                        <a  data-bs-toggle="modal"
                            data-bs-target="#chooseKonularForStepTwo"
                            @mouseenter="ders.active = true"
                            @mouseleave="ders.active = false"
                            @click="openDers($event,ders)"
                            class="card added-ders-container w-100" >
                            <div class="card-body">
                                <div class="d-flex flex-row align-items-center ps-5 ">
                                    <div class="symbol symbol-45px me-5 p-3" :style="{'background-color' : ders.color}">
                                        <img src="@/assets/img/ders.png" alt="">
                                    </div>
                                    <div class="d-flex justify-content-start flex-column" style="width:300px;">

                                        <div class="fw-bolder text-hover-primary fs-6 text-start">{{ ders.title }}</div>
                                        <span class="text-muted fw-bold text-muted d-block fs-7"> {{ ders.explanation ? ders.explanation.slice(0, 40) : '' }}...</span>
                                    </div>

                                    <div class="ms-4 link-badge" v-if="ders.topics.length != 0">
                                        {{ ders.topics.length }} adet konu eklendi
                                    </div>
                                    <div class="ms-4 link-badge" v-if="ders.topics.length == 0">
                                        Henüz Hiç Konu Ekli Değil Eklemek İçin Tıklayınız
                                    </div>
                                </div> 
                            </div>
                        </a>
                    </div>
                    <div>
                        <button class="h-100 btn btn-danger border-radius-0"  @click="removeChoosenDers($event,index)">
                            X
                        </button>
                    </div>
                </div>
            </TransitionGroup>
            <div class="card-footer">
                <div class="row">
                    <a
                    data-bs-toggle="modal"
                    data-bs-target="#newDersModalForStepTwo"
                    @click="newDers()"
                    class="btn btn-info">
                        Pakete Ders Ekle
                    </a>
            </div>
        </div>
    </div>
    <empty-modal modalId="newDersModalForStepTwo" ref="dersEkleModal" modalTitle="Ders Seç">
        <template v-slot:ModalBody>
            <div @click="chooseDers($event,ders)" v-for="(ders,index) in dersToBeShown" :key="index" class="ders-choosable">
                
                <div class="card-body" style="border-radius:5px; border:1px solid #F1F1F1;"  >
                    <div class="d-flex flex-row align-items-center ps-5 ">
                        <div class="symbol symbol-45px me-5 p-3" :style="{'background-color' : ders.color}">
                            <img :src="ders.icon" alt="">
                        </div>
                        <div class="d-flex justify-content-start flex-column" style="width:300px;">

                            <a href="javascript:void(0)" class="fw-bolder text-hover-primary fs-6 text-start">{{ ders.title }}</a>
                            <span class="text-muted fw-bold text-muted d-block fs-7"> {{ ders.explanation ? ders.explanation.slice(0, 40) : '' }}...</span>
                        </div>
                    </div> 
                </div>
            </div>
        </template>
        <template v-slot:ModalFooter>
            <h3>
                Pakete eklemek istediğiniz dersi seçiniz.
            </h3>
        </template>
    </empty-modal>
    <empty-modal modalId="chooseKonularForStepTwo" ref="chooseKonuModal" modalTitle="Konuları Seç   ">
        <template v-slot:ModalBody>
            <div v-if="isInChooseTopics" class="row flex-wrap justify-content-center">
                
                <div @click="chooseKonu($event,konu)" v-for="(konu,index) in konularToBeShown" :key="index" class="choosable-card" :class="{ 'choosable-card-choosen': konu.isChoosen || compareTopic(konu) }">
                   <h4 class="text-center"> {{konu.title}}</h4>
                </div>
            </div>
            <div v-if="!isInChooseTopics">
                <div v-for="(choosenTopic,index) in choosenDers.topics" :key="index">
                    <div class="choosenTopicsHeader shadow-sm mt-3 py-3 px-5 d-flex justify-content-between" >
                        <div class="d-flex align-items-center">
                            <h3 class="mb-0 text-white">
                                {{ choosenTopic.title }}
                            </h3>
                            <span class="rounded-badge">
                                {{ choosenTopic.videos.length }}
                            </span>
                        </div>
                        <div>
                            <el-button @click="addVideo($event,choosenTopic)" color="#626aef" plain>
                                <el-icon><Plus /></el-icon>
                            </el-button>
                            <el-button @click="choosenTopic.isVideosMinimized = !choosenTopic.isVideosMinimized" color="#626aef" plain>
                                <el-icon v-if="!choosenTopic.isVideosMinimized" style="transform:rotate(180deg);"><Bottom /></el-icon>
                                <el-icon v-if="choosenTopic.isVideosMinimized" ><Bottom /></el-icon>
                            </el-button>
                        </div>
                    </div>
                    <transition name="fadeHeight" mode="out-in">
                        <div class="card my-1 mx-1" :style=" choosenTopic.isVideosMinimized ? {'max-height':'0','overflow':'hidden','transition': 'all 1s ease'} : '' ">
                            <div class="card-body">
                                <add-video-card  v-for="(video,i) in choosenTopic.videos" @deleted="deleteVideoCard($event,i,index)" v-model="choosenTopic.videos[i]" :key="i">
                                </add-video-card>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </template>
        <template  v-slot:ModalFooter>
            <button v-if="isInChooseTopics" @click="isInChooseTopics = false" class="btn btn-info">Devam</button>
            <button v-if="!isInChooseTopics" @click="addChoosensToDers" class="btn btn-info">Kaydet</button>
        </template>
    </empty-modal>
    </div>
</template>

<script>
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import store from '@/store';
import EmptyModal from '@/components/modals/forms/EmptyModal.vue';
import  useCruds  from '@/composables/useCrud.js';
import { Plus, Bottom } from '@element-plus/icons-vue'
import { computed, onMounted ,reactive, ref } from "vue";
import { ElMessage } from 'element-plus';
import AddVideoCard from './elements/AddVideoCard.vue';
export default {
    components:{
        EmptyModal,
        Plus,
        AddVideoCard,
        Bottom,
    },

    setup() {
        const paket = ref({});
        const choosenDers = ref({});
        const dersEkleModal = ref();
        const chooseKonuModal = ref();
        const options = ref([]);
        const dersToBeShown = ref([]);
        const konularToBeShown = ref([]);
        const choosenTopics = ref([]);
        const isInChooseTopics = ref(true);



        const meta = ref({
            title:"",
            description:"",
            keywords:"",
        });

        const {loadCrud} = useCruds();

        const newDers = (element) => {

            loadCrud('lessons').then(function(response){
                if(response.success){
                    dersToBeShown.value = response.data;
                }
            });
        };
        const chooseDers = (event,ders) => {

            event.stopPropagation();
            ders.topics = [];
            paket.value.lessons.push(ders);
            dersEkleModal.value.close();

        }
        const removeChoosenDers = (event,index) => {

            paket.value.lessons.splice(index, 1);
            chooseKonuModal.value.close();
        }

        const saveChoosenKonular = () => {
            konularToBeShown.value.forEach((value) => {
                console.log(value);
            });
        }

        const compareTopic = (konu) => {
            let isSelected = false;
            choosenDers.value.topics.forEach((element) => {
                if(element.topicId == konu.topicId){
                    isSelected = true;
                    konu.isChoosen = true;
                }
            });
            return isSelected;
        }

        const openDers = (event,ders) => {
            konularToBeShown.value = [];

            isInChooseTopics.value = true;
            choosenDers.value = ders;
            loadCrud('topics').then(function(response){
                if(response.success){
                    response.data.forEach((element)=> {
                        element.isChoosen = false;
                        element.isVideosMinimized = false;
                        konularToBeShown.value.push(element);
                    });
                }
            });
        }
        const chooseKonu = (event,konu) => {
            konu.isChoosen = !konu.isChoosen;

            if(konu.isChoosen){
                choosenDers.value.topics.push(konu);
            }
            else {
                for (let i = 0; i < choosenDers.value.topics.length; i++) {
                    if (choosenDers.value.topics[i].topicId === konu.topicId) {
                        choosenDers.value.topics.splice(i, 1);
                    }
                }
            }
        };

        const addVideo = (event,topic) => {
            if(topic.isVideosMinimized){
                topic.isVideosMinimized = false;
            }
            topic.videos.push({videoId:'',title:'',embedUrl:''});

        }

        const addChoosensToDers = (event) => {
            chooseKonuModal.value.close();
            ElMessage({
                message: 'Konular ve Videolar başarılı bir şekilde eklendi.',
                type: 'success',
            });
        }

        const deleteVideoCard = (event,index,parendIndex) => {

            choosenDers.value.topics[parendIndex].videos.splice(index,1);

        }


        onMounted(() => {
            console.log("steptwo çalıştı");
            paket.value = store.getters.packageItem;
        });

        return {
            newDers,
            options,
            dersToBeShown,
            meta,
            chooseDers,
            dersEkleModal,
            removeChoosenDers,
            konularToBeShown,
            saveChoosenKonular,
            openDers,
            chooseKonuModal,
            chooseKonu,
            choosenDers,
            choosenTopics,
            isInChooseTopics,
            addVideo,
            addChoosensToDers,
            deleteVideoCard,
            compareTopic,
            paket,
        }
    },
    watch: {
        meta: {
            handler(val){
                store.commit(Mutations.SET_PRODUCT,{key:'meta',value:val} );
                console.log(store.getters.productItem);
            },
            deep: true
        }
    },
}
</script>

<style scoped>
    .ders-wrapper{
        width:100%;
        height:160px;
        background:#F1F1F1;
    }
    .ders-choosable{
        width:100%;
        margin:10px 0;
        cursor:pointer;
        color:white;
        display:flex;
        align-items:center;
        justify-content:center;
        align-content:center;
    }
    .added-ders-container{
        position:relative;
        cursor:pointer;
        border:1px solid #F1F1F1;
    }
    .active-ders .actions-sidebar{
        display:flex;
    }
    .added-ders-container:hover{
        background:linear-gradient(135deg, rgba(114,57,234,1) 0%, rgba(87,64,135,1) 100%);
        color:#FFFFFF;

    }
    .added-ders-container:hover a {
        color:#FFFFFF;
    }
    .actions-sidebar{
        display:none;
        width:200px;
        height:100%;
        position:absolute;
        right:0;
        align-items:center;
        justify-content:space-around;
    }

    .fade-move,
    .fade-enter-active,
    .fade-leave-active {
        transition: all 0.5s ease-out;
    }

    /* 2. declare enter from and leave to state */
    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
        transform:translateX(100%);

    }
    .fade-leave-active{
        position:absolute;
    }

    .choosable-card{
        background:#FFF;
        border-radius:10px;
        display:flex;
        align-items:center;
        align-content:center;
        justify-content:center;
        cursor: pointer;
        height: 120px;
        width:200px;
        border: 1px dashed #6e6e6e;
        margin: 15px;
    }
    .choosable-card:hover{
        transform:scale(0.95);
        transition:all 0.2s ease-in;
    }
    .choosable-card:hover h4{
        color:#000;
    }
    .choosable-card-choosen{
        background: linear-gradient(135deg, rgba(114,57,234,1) 0%, rgba(87,64,135,1) 100%);
        color:#FFF;
    }
    .choosable-card-choosen h4{
        color:#FFF !important;
    }
    .choosenTopicsHeader{
         background: linear-gradient(135deg, rgba(114,57,234,1) 0%, rgba(87,64,135,1) 100%);
         color:#FFF;
         border-radius:12px;

    }
    .link-badge{
        border:1px dashed #F1F1F1;
        border-radius:4px;
        background:#626aef;
        padding:5px 10px;
        color:#fff;
    }
    .rounded-badge{
        background:rgb(239, 240, 253);
        border-radius:50%;
        width:32px;
        height:32px;
        display:flex;
        align-items:center;
        align-content:center;
        justify-content:center;
        margin-left:5px;
        color:#000;
    }




    .fadeHeight-enter-active,
    .fadeHeight-leave-active {
    transition: all 0.2s;
    max-height: 230px;
    }
    .fadeHeight-enter,
    .fadeHeight-leave-to
    {
    opacity: 0;
    max-height: 0px;
    }
</style>