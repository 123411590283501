<template>
    <div class="d-flex flex-row my-2" >
    
        <div class="card bg-light">
            <div class="card-header my-4 justify-content-between border-bottom-0" style="min-height:50px;">
                <div class="flex-grow-1 d-flex align-items-center">
                    <h3>{{ theModel.title }}</h3>
                </div>
                <el-button @click="$emit('deleted')" color="#626aef" plain>
                    <el-icon><Delete /></el-icon>
                </el-button>
            </div>
            <div class="card-body bg-light p-3">
                
                <div class="row">
                    <div class="col-md-7"> 
                        <el-input v-model="theModel.title" class="mb-5" placeholder="Video Başlık" />
                        <el-input v-model="theModel.url" @input="checkUrl" :style="{'unvalidated-input' : isValidUrl}" placeholder="Video Linki Giriniz"  />
                    </div> 
                    <div class="col-md-4"> 
                        <div class="video-preview d-flex align-items-center justify-content-center" style="position:relative; overflow:hidden;">          
                            <vertical-loader v-if="videoLoading"></vertical-loader>
                            <vimeo-player v-if="getVideoId != ''" ref="player" :video-id="getVideoId" :player-height="100"  :controls="true" @error="onError" @ready="onReady"/>
                            <span v-if="hasErrorOnVideo">Aradığınız Video Bulunamadı</span>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent, computed, ref, onErrorCaptured } from 'vue';
import { Delete } from '@element-plus/icons-vue';
import VerticalLoader from './VerticalLoader.vue';
export default defineComponent({
    name: 'AddVideoCard',
    components:{
        Delete,
        VerticalLoader,
    },
    props: {
        modelValue: {           // Declare the property
            type: Object,
            default: () => ({}),  // Do not forget about default value
        },
    },
    setup(props, { emit }) {
        const theModel = computed({  // Use computed to wrap the object
            get: () => props.modelValue,
            set: (value) => emit('update:modelValue', value),
        });

        const isValidUrl = ref(false);
        const hasErrorOnVideo = ref(false);
        const videoLoading = ref(false);
        const height = ref(200);
        const width = ref(100);
        const options = ref({
				muted: true,
                autoplay: false,
			});
        const playerReady = ref(false);



        const getVideoId = computed(() => {
            
            if(isValidUrl.value){
                return theModel.value.url.split("/").pop();
            }
            else {
                return '';
            }
        })
                
        const onReady = () => {

            videoLoading.value = false;
			playerReady.value = true     
        }

        const checkUrl = (event) => {
            if(event.includes('http')){
                isValidUrl.value = true;
                videoLoading.value = true;
            }
            else {
                isValidUrl.value = false;
            }
            
        }
        onErrorCaptured(() => {
            console.log("bir hata yakalandı");
        });

        const  onError = (event) => {
            console.log("there is an error");
            hasErrorOnVideo.value = true;
            videoLoading.value = false;
        }
        return { 
            theModel,
            isValidUrl,
            videoLoading,
            height,
            width,
            getVideoId,
            onReady,
            checkUrl,
            onError,
            hasErrorOnVideo
        };
    }
});
</script>
<style scoped>
    .video-preview{
        min-width:200px;
        min-height:100px;
        margin: 0 20px;
        border:1px solid #E4E6EF;
    }
    .unvalidated-input{
        border:1px solid #F1416C;
    }
</style>